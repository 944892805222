<template>
  <div class="submit-asset-page content">
    <BaseBreadcrumbs :breadcrumbs="parentBreadcrumbs || breadcrumbs" />
    <h1 v-if="!isCms">Submit New Asset</h1>
    <form autocomplete="off" method="post" v-on:submit.prevent="submitForm">
      <section>
        <div class="form-group" :class="{ 'has-error': errors.name }">
          <label for="asset-name" class="form-label">Asset Name<sup>*</sup></label>
          <input
            v-model="formData.name"
            type="text"
            class="form-control"
            id="asset-name"
            placeholder="Asset name"
            maxlength="75"
            :class="{ 'is-invalid': errors.name }"
          />
          <template v-if="errors.name">
            <div v-for="message in errors.name.messages" :key="message" class="invalid-feedback">
              {{ message }}
            </div>
          </template>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.assetType }">
          <label for="asset-type" class="form-label">Asset Type<sup>*</sup></label>
          <BaseDropdown
            id="asset-type"
            :btnText="formData.assetType"
            :dropdownOptions="availableAssetTypes"
            :btnPlaceholder="'Select an asset type'"
            @selection-clicked="updateAssetType($event)"
            :class="{ 'is-invalid': errors.assetType }"
          />
          <template v-if="errors.assetType">
            <div v-for="message in errors.assetType.messages" :key="message" class="invalid-feedback">
              {{ message }}
            </div>
          </template>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.assetFile }">
          <BaseFileUpload
            @file-added="fileAdded($event)"
            @file-error="fileUploadErrorHandler($event)"
            :existingFileName="formData.s3Key"
            :class="{ 'is-invalid': errors.assetFile }"
            :fileTypesAccept="validFileExtensions"
            :fileSizeLimit="250"
            isRequired
            label="Upload"
          />
          <template v-if="errors.assetFile">
            <div v-for="message in errors.assetFile.messages" :key="message" class="invalid-feedback">
              {{ message }}
            </div>
          </template>
          <div class="form-text" v-if="formData.assetType === 'PDF'">
            PDF uploads cannot exceed 30 MB in size.<br />Accepted file types: PDF
          </div>
          <div class="form-text" v-if="formData.assetType === 'Image'">
            Image uploads cannot exceed 15 MB in size.<br />Accepted file types: JPG, PNG, GIF
          </div>
          <div class="form-text" v-if="formData.assetType === 'Video'">
            Video uploads cannot exceed 250MB size.<br />Accepted file types: MP4, MOV
          </div>
          <div class="form-text" v-if="formData.assetType === 'MP3'">
            Audio uploads cannot exceed 10MB size.<br />Accepted file types: MP3
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.description }">
          <label for="asset-description" class="form-label">Asset Description<sup>*</sup></label>
          <textarea
            v-model="formData.description"
            type="text"
            class="form-control"
            id="asset-description"
            placeholder="Type your description here..."
            rows="5"
            maxlength="1000"
            :class="{ 'is-invalid': errors.description }"
          ></textarea>
          <template v-if="errors.description">
            <div v-for="message in errors.description.messages" :key="message" class="invalid-feedback">
              {{ message }}
            </div>
          </template>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="formData.isPublic" id="isPublic" />
            <label class="form-check-label" for="isPublic">
              Allow this link to be shared publicly (e.g. with customers/non-Audi employees)
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="formData.isDownloadEnabled"
              id="isDownloadEnabled"
            />
            <label class="form-check-label" for="isDownloadEnabled">
              Allow users to download this asset to their device
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="formData.useOwnerName" id="useOwnerName" />
            <label class="form-check-label" for="useOwnerName">
              Include my name and dealership name on the asset listing
            </label>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div class="form-row">
          <div class="form-group" :class="{ 'has-error': errors.year }">
            <label for="model-year" class="form-label">Model Year<sup>*</sup></label>
            <BaseDropdown
              id="model-year"
              :btnText="formData.year"
              :dropdownOptions="availableYears"
              :btnPlaceholder="'Select a year'"
              @selection-clicked="updateYear($event)"
              :class="{ 'is-invalid': errors.year }"
            />
            <template v-if="errors.year">
              <div v-for="message in errors.year.messages" :key="message" class="invalid-feedback">
                {{ message }}
              </div>
            </template>
          </div>
          <div class="form-group" :class="{ 'has-error': errors.model }">
            <label for="model" class="form-label">Model<sup>*</sup></label>
            <BaseDropdown
              id="model"
              :btnText="formData.model"
              :dropdownOptions="availableModels"
              :btnPlaceholder="'Select a model'"
              @selection-clicked="updateModel($event)"
              :class="{ 'is-invalid': errors.model }"
            />
            <template v-if="errors.model">
              <div v-for="message in errors.model.messages" :key="message" class="invalid-feedback">
                {{ message }}
              </div>
            </template>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.jobRole }">
          <label class="form-label">Job Role<sup>*</sup></label>
          <div class="job-role-checkboxes" :class="{ 'is-invalid': errors.jobRole }">
            <div v-for="(jobRole, index) in config.jobRoles" :key="index" class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                :id="'role-checkbox-' + index"
                :value="jobRole.id"
                v-model="formData.jobRole"
              />
              <label class="form-check-label" :for="'role-checkbox-' + index">{{ jobRole.name }}</label>
            </div>
          </div>
          <template v-if="errors.jobRole">
            <div v-for="message in errors.jobRole.messages" :key="message" class="invalid-feedback">
              {{ message }}
            </div>
          </template>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.assetCategory }">
          <label class="form-label">Asset Category<sup>*</sup></label>
          <div class="category-checkboxes" :class="{ 'is-invalid': errors.assetCategory }">
            <div v-for="(category, index) in config.categories" :key="index" class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                :id="'category-checkbox-' + index"
                :value="category.id"
                v-model="formData.assetCategory"
              />
              <label class="form-check-label" :for="'category-checkbox-' + index">{{ category.name }}</label>
            </div>
          </div>
          <template v-if="errors.assetCategory">
            <div v-for="message in errors.assetCategory.messages" :key="message" class="invalid-feedback">
              {{ message }}
            </div>
          </template>
        </div>
        <button v-if="!isEdit" class="btn btn-primary" type="submit">
          {{ isCms ? 'Add Asset' : 'Submit New Asset' }}
        </button>
        <button v-else class="btn btn-primary" type="submit">Save Asset</button>
      </section>
    </form>
    <div class="toast-container">
      <BaseToast v-if="toastMessage.isShown" :toast="toastMessage" />
    </div>
  </div>
</template>

<script>
import BaseBreadcrumbs from '@/components/BaseBreadcrumbs.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import BaseToast from '@/components/BaseToast.vue';
import BaseFileUpload from '@/components/BaseFileUpload.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import ApiService from '@/services/api.service';

function initialFormData() {
  return {
    name: '',
    assetType: '',
    assetTypeId: '',
    assetFile: null,
    fileName: '',
    s3Key: '',
    description: '',
    isPublic: false,
    isDownloadEnabled: false,
    useOwnerName: false,
    modelYearId: 0,
    year: '',
    model: '',
    useAllJobRoles: false,
    jobRole: [],
    assetCategory: [],
  };
}

export default {
  components: {
    BaseBreadcrumbs,
    BaseDropdown,
    BaseToast,
    BaseFileUpload,
  },
  data() {
    return {
      formData: initialFormData(),
      toastMessage: {
        status: 'danger',
        message: '',
        isShown: false,
      },
      errors: {},
      breadcrumbs: [],
    };
  },
  props: {
    editAssetData: Object,
    parentBreadcrumbs: Array,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name) {
        vm.breadcrumbs.push({ title: from.name, link: from.path });
        if (to.path.includes('/cms/')) {
          vm.breadcrumbs.push({ title: to.name, link: to.path });
        } else {
          vm.breadcrumbs.push({ title: 'Submit New Asset', link: '/asset/submit' });
        }
      } else if (to.path.includes('/cms/')) {
        vm.breadcrumbs = [
          { title: 'Audi Collection', link: '/portal/cms/audi-collection' },
          { title: 'Add Asset', link: '/portal/cms/add-asset' },
        ];
      } else {
        vm.breadcrumbs = [
          { title: 'Content Library', link: '/' },
          { title: 'Submit New Asset', link: '/asset/submit' },
        ];
      }
    });
  },
  watch: {
    editAssetData(newEditAssetData) {
      this.formData = newEditAssetData;
    },
  },
  computed: {
    ...mapGetters(['config', 'filters']),
    availableAssetTypes() {
      const filterIndex = this.filters.findIndex((filter) => filter.title === 'Asset Type');
      return this.filters[filterIndex].parameters;
    },
    availableYears() {
      const availableYears = [];
      this.config.modelYears.forEach((modelYear) => {
        if (!availableYears.some((year) => year.name === modelYear.modelYear)) {
          const thisYear = {};
          thisYear.name = modelYear.modelYear;
          thisYear.value = modelYear.modelYear;
          availableYears.push(thisYear);
        }
      });
      availableYears.sort((a, b) => a.value - b.value);
      return availableYears;
    },
    availableModels() {
      if (this.formData.year) {
        const filteredModelYears = this.config.modelYears.filter(
          (modelYear) => modelYear.modelYear === this.formData.year,
        );
        const availableModels = [];
        filteredModelYears.forEach((modelYear) => {
          const model = {};
          model.value = modelYear.id;
          model.name = modelYear.modelName;
          availableModels.push(model);
        });
        return availableModels;
      }
      return [
        {
          name: 'Select a year',
          value: '',
        },
      ];
    },
    isCms() {
      return this.$route.path.includes('/cms/');
    },
    isEdit() {
      return !!this.editAssetData;
    },
    validFileExtensions() {
      switch (this.formData.assetType) {
        case 'PDF':
          return '.pdf';
        case 'Image':
          return '.jpg, .jpeg, .png, .gif';
        case 'Video':
          return '.mp4, .mov';
        case 'MP3':
          return '.mp3';
        default:
          return '';
      }
    },
  },
  methods: {
    updateAssetType(event) {
      this.formData.assetType = event.name;
      this.formData.assetTypeId = event.id;
    },
    fileAdded(event) {
      this.formData.assetFile = event;
    },
    handleFileUpload() {
      this.formData.assetFile = this.$refs.assetFile.files[0];
    },
    updateYear(event) {
      this.formData.year = event.name;
    },
    updateModel(event) {
      this.formData.model = event.name;
      this.formData.modelYearId = event.value;
    },
    submitForm() {
      if (this.validateForm()) {
        if (this.isEdit) {
          this.putEditAsset();
        } else {
          this.postCreateAsset();
        }
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.toastMessage.status = 'danger';
        this.toastMessage.message = 'Please complete filling out the form.';
        this.toastMessage.isShown = true;
      }
    },
    postCreateAsset() {
      const filename = this.formData.assetFile.name;
      const postRoute = this.isCms ? '/admin/assets' : '/assets';
      axios
        .post(`${process.env.VUE_APP_API_URL}/assets/upload?fileName=${filename}`)
        .then((uploadResponse) => {
          const s3Url = uploadResponse.data.data.url;
          this.formData.s3Key = uploadResponse.data.data.params.Key;
          const instance = axios.create({
            headers: { 'Content-Type': this.formData.assetFile.type },
          });
          delete instance.defaults.headers.common.Authorization;
          instance
            .put(s3Url, this.formData.assetFile)
            .then(() => {
              // File uploaded successfully
              const formData = {};
              Object.keys(this.formData).forEach((key) => {
                if (typeof this.formData[key] === 'boolean') {
                  formData[key] = this.formData[key] ? 1 : 0;
                } else {
                  formData[key] = this.formData[key];
                }
              });
              axios
                .post(`${process.env.VUE_APP_API_URL}${postRoute}`, formData)
                .then(() => {
                  if (this.isCms) {
                    this.$store.dispatch('getCmsAssets', true); // Pass true to force refresh cached data
                  } else {
                    this.$store.dispatch('getAssets', true); // Pass true to force refresh cached data
                  }
                  this.resetForm();
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                  this.toastMessage.status = 'success';
                  this.toastMessage.message = 'Your asset has been submitted successfully.';
                  this.toastMessage.isShown = true;
                })
                .catch((error) => {
                  console.error(error.response);
                  this.setErrorMessage();
                });
            })
            .catch((error) => {
              console.error(error.response);
              this.setErrorMessage();
            });
        })
        .catch((error) => {
          console.error(error.response);
          this.setErrorMessage();
        });
    },
    putEditAsset() {
      // Check if we have a newly uploaded file
      if (this.formData.assetFile) {
        // Get S3 presigned url
        this.getImagePostRequest(this.formData.assetFile).then(({ data }) => {
          if (data.data) {
            // PUT image against S3 url
            this.putImageRequest(data.data, this.formData.assetFile);
          }
        });
      }
      const payload = { ...this.formData };

      // convert booleans to binary
      Object.keys(payload).forEach((key) => {
        if (typeof payload[key] === 'boolean') {
          payload[key] = payload[key] ? 1 : 0;
        }
      });

      ApiService.put('/admin/assets', payload).then(({ data }) => {
        this.$emit('saved', data?.data?.assetInfo);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.toastMessage.status = 'success';
        this.toastMessage.message = 'Your asset has been updated successfully.';
        this.toastMessage.isShown = true;
      });
    },
    getImagePostRequest(assetFile) {
      // Check to see if we've attached a new assetFile
      if (assetFile) {
        const fileName = assetFile.name;
        return ApiService.post(`/assets/upload?fileName=${fileName}`)
          .then((resp) => resp?.data?.data)
          .catch((error) => {
            this.setErrorMessage();
            Promise.reject(Error(error));
          });
      }
      // Resolve if we don't have a new assetFile
      return Promise.resolve();
    },
    putImageRequest(uploadResponse, assetFile) {
      const s3Url = uploadResponse?.url;
      assetFile.s3Key = uploadResponse?.params?.Key;
      const axiosS3Instance = axios.create({
        headers: { 'Content-Type': assetFile.type },
      });
      delete axiosS3Instance.defaults.headers.common.Authorization;
      return axiosS3Instance.put(s3Url, assetFile).catch((error) => {
        this.setErrorMessage(error);
      });
    },
    setErrorMessage() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.toastMessage.status = 'danger';
      this.toastMessage.message = 'There was an error submitting the form.';
      this.toastMessage.isShown = true;
    },
    getFileExtension(filename) {
      if (filename) {
        const lastDot = filename.lastIndexOf('.');
        return filename.substring(lastDot + 1);
      }
      return null;
    },
    validateForm() {
      this.errors = {};
      if (this.formData.name === '') {
        this.errors.name = {};
        this.errors.name.messages = [];
        this.errors.name.messages.push('Please enter a name for the asset.');
      }
      if (this.formData.assetType === '') {
        this.errors.assetType = {};
        this.errors.assetType.messages = [];
        this.errors.assetType.messages.push('Please enter an asset type.');
      }
      if (!this.formData.assetFile && !this.formData.s3Key) {
        this.errors.assetFile = {};
        this.errors.assetFile.messages = [];
        this.errors.assetFile.messages.push('Please upload a file for the asset.');
      } else {
        const extension = this.getFileExtension(this.formData.assetFile?.name)?.toLowerCase();
        if (
          !this.formData.s3Key &&
          ((this.formData.assetType === 'PDF' && extension !== 'pdf') ||
            (this.formData.assetType === 'Image' &&
              extension !== 'jpg' &&
              extension !== 'jpeg' &&
              extension !== 'png' &&
              extension !== 'gif') ||
            (this.formData.assetType === 'Video' && extension !== 'mp4' && extension !== 'mov') ||
            (this.formData.assetType === 'MP3' && extension !== 'mp3'))
        ) {
          this.errors.assetFile = {};
          this.errors.assetFile.messages = [];
          this.errors.assetFile.messages.push('This is not a valid file type.');
        }
      }
      if (this.formData.description === '') {
        this.errors.description = {};
        this.errors.description.messages = [];
        this.errors.description.messages.push('Please enter a description.');
      }
      if (this.formData.year === '') {
        this.errors.year = {};
        this.errors.year.messages = [];
        this.errors.year.messages.push('Please enter a model year.');
      }
      if (this.formData.model === '') {
        this.errors.model = {};
        this.errors.model.messages = [];
        this.errors.model.messages.push('Please enter a model.');
      }
      if (this.formData.jobRole.length === 0) {
        this.errors.jobRole = {};
        this.errors.jobRole.messages = [];
        this.errors.jobRole.messages.push('Please specify at least one job role.');
      }
      if (this.formData.assetCategory.length === 0) {
        this.errors.assetCategory = {};
        this.errors.assetCategory.messages = [];
        this.errors.assetCategory.messages.push('Please specify at least one asset category.');
      }
      return Object.keys(this.errors).length === 0;
    },
    resetForm() {
      Object.assign(this.formData, initialFormData());
    },
    fileUploadErrorHandler(error) {
      if (!this.errors.assetFile) {
        this.errors.assetFile = {};
        this.errors.assetFile.messages = [];
        this.errors.assetFile.messages.push(error);
      } else {
        this.errors.assetFile.messages.push(error);
      }
      this.errors = { ...this.errors };
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-asset-page {
  position: relative;
}
section {
  max-width: 600px;
  ::v-deep .dropdown button {
    width: 100%;
    max-width: none;
  }
}
h1 {
  margin-bottom: 1.875rem; // 30px
}
hr {
  margin: 1.25rem 0; // 20px
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;
    &:not(:first-child) {
      margin-left: 2rem; // 32px
    }
  }
}
.form-check {
  margin-bottom: 0.9375rem; // 15px
}
.job-role-checkboxes {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @include bp-md-tablet {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.category-checkboxes {
  @include bp-md-tablet {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.job-role-checkboxes ~ .invalid-feedback,
.category-checkboxes ~ .invalid-feedback {
  margin-top: 0;
}
</style>
