<template>
  <div class="upload-container">
    <label for="asset-file" class="form-label">{{ label }}<sup v-if="isRequired">*</sup></label>
    <div class="file-upload form-control">
      <input
        class="form-control"
        type="file"
        id="asset-file"
        ref="assetFile"
        v-on:change="handleFileUpload"
        :accept="fileTypesAccept"
      />
      <span>{{ filename }}</span>
      <button><IconUpload /> Choose file</button>
    </div>
  </div>
</template>

<script>
import IconUpload from '@/assets/icons/upload_icon.svg';

export default {
  components: {
    IconUpload,
  },
  data() {
    return {
      filename: '',
    };
  },
  props: {
    fileTypesAccept: String,
    existingFileName: String,
    fileSizeLimit: {
      type: Number,
      default: () => 0,
    },
    label: String,
    isRequired: Boolean,
  },
  created() {
    this.filename = this.existingFileName;
  },
  watch: {
    existingFileName(newFileName) {
      this.filename = newFileName;
    },
  },
  methods: {
    handleFileUpload() {
      const file = this.$refs.assetFile.files[0];
      if (file) {
        if (this.fileSizeLimit === 0 || file?.size <= this.fileSizeLimit * 1000000) {
          this.$emit('file-added', file);
          this.filename = file.name;
          this.errorMessage = '';
        } else {
          this.filename = '';
          this.$emit('file-error', `File size must be less than ${this.fileSizeLimit}MB.`);
        }
      } else {
        this.filename = '';
        this.$emit('file-error', 'Please upload a file for the asset.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-container {
  &.is-invalid {
    color: $error;
    .file-upload {
      border-bottom-color: $error;
    }
    span,
    label {
      color: $error;
    }
  }
  .form-label {
    margin: 0;
  }
  .file-upload {
    position: relative;
    display: flex;
    align-items: center;
    input {
      position: absolute;
      width: 100%;
      cursor: pointer;
      outline: none !important;
      opacity: 0;
    }
    button {
      padding: 0 0 0.625rem 0; // 10px
      margin-left: auto;
      background: transparent;
      border: 1px solid transparent;
      svg {
        width: 1em;
        height: 1em;
        margin-right: 0.9375rem; // 15px
      }
    }

    .placeholder {
      color: $grey--medium;
    }
  }
}
</style>
